var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.addEnabled)?_c('v-btn',{attrs:{"bottom":"","color":"pink","dark":"","fab":"","fixed":"","right":""},on:{"click":function($event){return _vm.$emit('addItem')}}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e(),(_vm.error)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(_vm._s(_vm.error))]):_vm._e(),(!_vm.items)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_vm._e(),(_vm.items)?_c('v-card',[_c('v-card-title',[_c('strong',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.title))]),(_vm.refetch)?_c('v-btn',{staticClass:"mr-2 mt-1",attrs:{"outlined":"","x-small":""},on:{"click":function($event){return _vm.$emit('refetch')}}},[_vm._v("Odśwież")]):_vm._e(),_vm._t("title-buttons-left"),_c('v-spacer'),_vm._t("title-buttons-right"),_c('v-spacer'),(_vm.enableSearch)?_c('v-text-field',{attrs:{"append-icon":"mdi-search","label":"Szukaj","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e()],2),_c('v-data-table',_vm._b({ref:"dataTable",attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"footer-props":{ 'items-per-page-options': [15, 30, 50] },"options":_vm.options},on:{"update:page":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.reemit.apply(void 0, [ 'update:page' ].concat( argsArray ))},"update:options":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.reemit.apply(void 0, [ 'update:options' ].concat( argsArray ))},"update:sortBy":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.reemit.apply(void 0, [ 'update:sortBy' ].concat( argsArray ))},"click:row":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.reemit.apply(void 0, [ 'click:row' ].concat( argsArray ))}},scopedSlots:_vm._u([{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.createdAt))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.acl_edit)?_c('v-btn',{attrs:{"x-small":"","outlined":""},on:{"click":function($event){return _vm.$emit('editItem', item)}}},[_vm._v("edytuj")]):_vm._e(),_c('v-btn',{staticClass:"ml-2",attrs:{"x-small":"","outlined":""},on:{"click":function($event){return _vm.$emit('displayItem', item)}}},[_vm._v("pokaż")])]}},_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}})],null,true)},'v-data-table',_vm.$props,false))],1):_vm._e(),_vm._t("default",null,{"id":"forms"})],2)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <span>
    <slot name="activator" v-bind:openDialog="openDialog" v-bind:buttonProps="buttonProps">
      <v-btn v-bind="buttonProps" @click="openDialog">
        {{ buttonLabel || label }}
      </v-btn>
    </slot>
    <v-dialog
      :max-width="maxWidth"
      :value="dialog"
      persistent
      :fullscreen="false"
      :scrollable="true"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ label }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="$refs.modalForm.mutate()" :loading="$apollo.loading">{{ submitLabel }}</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container style="overflow: auto">
          <AbstractForm ref="modalForm" v-bind="$props" @done="handleDone" />
        </v-container>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import AbstractForm from '@/components/Form/AbstractForm.vue';

export default {
  name: 'AbstractModalForm',
  components: { AbstractForm },
  extends: AbstractForm,
  props: {
    label: String,
    buttonLabel: {
      type: String,
    },
    submitLabel: {
      type: String,
      default: 'Zapisz',
    },
    buttonOutlined: {
      type: Boolean,
      required: false,
      default: true,
    },
    buttonProps: {
      type: Object,
      required: false,
      default: () => {
        return { 'x-small': true, 'outlined': true };
      },
    },
    maxWidth: {
      type: String,
      default: '800px',
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    makeSmartQueryConfig() {
      return {
        query: this.query,
        variables() {
          return this.queryVariables(this.entityId);
        },
        skip: () => !this.dialog, // lazyload query
        update: this.queryUpdate,
      };
    },
    handleDone(...data) {
      this.$emit('done', ...data);
      this.dialog = false;
    },
    openDialog() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$emit('close');
    },
    save() {
      this.$refs.modalForm.mutate();
    },
  },
  mounted() {
    if (this.$refs.modalForm) {
      this.$refs.modalForm.resetValidation();
    }
  },
};
</script>

<style scoped></style>

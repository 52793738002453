<template>
  <div>
    <v-btn v-if="addEnabled" bottom color="pink" dark fab fixed right @click="$emit('addItem')">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-alert v-if="error" type="error">{{ error }}</v-alert>
    <v-skeleton-loader v-if="!items" type="table"></v-skeleton-loader>
    <v-card v-if="items">
      <v-card-title>
        <strong class="mr-2">{{ title }}</strong>
        <v-btn class="mr-2 mt-1" v-if="refetch" @click="$emit('refetch')" outlined x-small>Odśwież</v-btn>
        <slot name="title-buttons-left" />
        <v-spacer></v-spacer>
        <slot name="title-buttons-right" />
        <v-spacer></v-spacer>
        <v-text-field
          v-if="enableSearch"
          v-model="search"
          append-icon="mdi-search"
          label="Szukaj"
          single-line
          hide-details
        />
      </v-card-title>
      <v-data-table
        v-bind="$props"
        ref="dataTable"
        :headers="headers"
        :items="items"
        :search="search"
        @update:page="reemit('update:page', ...arguments)"
        @update:options="reemit('update:options', ...arguments)"
        @update:sortBy="reemit('update:sortBy', ...arguments)"
        @click:row="reemit('click:row', ...arguments)"
        :footer-props="{ 'items-per-page-options': [15, 30, 50] }"
        :options="options"
      >
        <template v-slot:[`item.active`]="{ item }">
          <v-simple-checkbox v-model="item.active" disabled></v-simple-checkbox>
        </template>

        <template v-slot:[`item.createdAt`]="{ item }">
          {{ item.createdAt | date }}
        </template>

        <template v-slot:[`item.action`]="{ item }">
          <v-btn v-if="item.acl_edit" x-small outlined @click="$emit('editItem', item)">edytuj</v-btn>
          <v-btn class="ml-2" x-small outlined @click="$emit('displayItem', item)">pokaż</v-btn>
        </template>

        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
          <slot :name="slot" v-bind="scope" />
        </template>
      </v-data-table>
    </v-card>
    <slot id="forms"></slot>
  </div>
</template>

<script>
// import gql from 'graphql-tag';

export default {
  name: 'ServerSideDataGrid',
  data() {
    return {
      value: null,
      error: null,
      search: '',
      test: 'string',
    };
  },
  watch: {
    search(newValue) {
      this.$emit('search', newValue);
    },
  },
  methods: {
    reemit(event, data) {
      this.$emit(event, data);
    },
  },
  computed: {
    computedOptions: {
      set() {
        // ignore
      },
      get() {
        return this.options;
      },
    },
  },
  props: {
    allowRefetch: Boolean,
    showExpand: Boolean,
    singleExpand: Boolean,
    showSelect: Boolean,
    serverItemsLength: {
      type: Number,
      required: false,
    },
    dense: {
      type: Boolean,
      required: false,
    },
    options: {
      type: Object,
      required: false,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    enableSearch: { type: Boolean, default: false },
    title: {
      type: String,
      required: false,
    },
    addEnabled: {
      type: Boolean,
      default: false,
    },
    refetch: {
      type: Boolean,
      default: false,
    },
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
    sortBy: {
      type: Array,
      required: false,
    },
    sortDesc: {
      type: Array,
      required: false,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    variables: {
      type: Object,
      false: true,
      default: () => {},
    },
    update: {
      type: Function,
      default: function _default(data) {
        return data;
      },
    },
  },
};
</script>

<template>
  <span>
    <ServerSideDataGrid
      :itemsPerPage="options.itemsPerPage"
      @displayItem="reemit('displayItem', ...arguments)"
      :serverItemsLength="serverItemsLength"
      :loading="$apollo.queries.items.loading"
      @update:options="onUpdateOptions"
      @click:row="reemit('click:row', ...arguments)"
      v-bind="$props"
      ref="dataTable"
      :headers="headers"
      :items="items"
      :options="options"
      refetch
      @refetch="refetch"
      @search="handleSearchChange"
    >
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="modifyScope(scope)" />
      </template>
    </ServerSideDataGrid>
  </span>
</template>
<script>
import { debounce } from 'debounce';
import ServerSideDataGrid from '@/components/Common/ServerSideDataGrid.vue';

export default {
  name: 'GraphqlServerSideDataGrid',
  components: { ServerSideDataGrid },
  props: {
    forcedFilters: {
      type: Array,
      required: false,
      default: () => [],
    },
    headers: {
      type: Array,
      required: true,
    },
    query: {
      type: Object,
      required: true,
    },
    enableSearch: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    sortBy: {
      type: Array,
      required: false,
      default: () => ['createdAt'],
    },
    dense: {
      type: Boolean,
      required: false,
    },
    sortDesc: {
      type: Array,
      required: false,
      default: () => [true],
    },
    itemsPerPage: {
      type: Number,
      required: false,
      default: () => 15,
    },
  },
  computed: {
    filters() {
      const data = [];
      // eslint-disable-next-line guard-for-in
      for (const filterKey in this.clientSideFilters) {
        const { type } = this.clientSideFilters[filterKey];
        const { value } = this.clientSideFilters[filterKey];
        if (!value) {
          continue;
        }
        switch (type) {
          case 'IN':
            data.push({ field: filterKey, type, arrayValue: value });
            break;
          default:
            data.push({ field: filterKey, type, value });
            break;
        }
      }
      return data;
    },
    sortMapper() {
      const tempArray = [];
      for (const key in this.options.sortBy) {
        if (typeof this.options.sortBy[key] === 'undefined') {
          continue;
        }
        tempArray.push({ field: this.options.sortBy[key], direction: this.options.sortDesc[key] ? 'DESC' : 'ASC' });
      }
      return tempArray;
    },
  },
  data() {
    const clientSideFilters = {};
    for (const header of this.headers) {
      clientSideFilters[header.value] = {};
    }
    return {
      searchFilter: [],
      options: {
        page: 1,
        itemsPerPage: this.itemsPerPage,
        sortBy: this.sortBy,
        sortDesc: this.sortBy,
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false,
      },
      rand: Math.random(),
      clientSideFilters,
      serverItemsLength: 0,
    };
  },
  methods: {
    handleSearchChange: debounce(function (newValue) {
      if (!newValue) {
        this.searchFilter = [];
        return;
      }

      this.searchFilter = [{ field: 'search', type: 'EQ', value: newValue }];
    }, 400),
    modifyScope(scope) {
      // eslint-disable-next-line no-param-reassign
      scope.applyFilter = this.applyFilter;
      // eslint-disable-next-line no-param-reassign
      scope.filters = this.filters;
      return scope;
    },
    buildFilterRow(field, value, type) {
      return { field, type, value };
    },
    applyFilter({ field, value, type }) {
      this.clientSideFilters[field] = this.buildFilterRow(field, value, type);
    },
    onUpdateOptions(options) {
      this.options = options;
    },
    reemit(event, data) {
      this.$emit(event, data);
    },
    refetch() {
      this.$apollo.queries.items.refetch();
    },
  },
  apollo: {
    items: {
      query() {
        return this.query;
      },
      variables() {
        return {
          query: {
            orders: this.sortMapper,
            filters: [...this.filters, ...this.forcedFilters, ...this.searchFilter],
          },
          limit: this.options.itemsPerPage,
          offset: (this.options.page - 1) * this.options.itemsPerPage,
        };
      },
      update(data) {
        this.serverItemsLength = data.list.count;
        return data.list.items;
      },
    },
  },
};
</script>
